import http from '@/services/http'

const state = {
  community: null
}

// getters
const getters = {}

// actions
const actions = {
  async create (c, payload) {
    try {
      const { data } = await http.post('communities', payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async get ({ commit }, communityId) {
    try {
      const { data } = await http.get(`communities/${communityId}`)
      commit('SET_COMMUNITY', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async update ({ commit }, { communityId, payload }) {
    try {
      const { data } = await http.patch(`communities/${communityId}`, payload)
      commit('SET_COMMUNITY', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_COMMUNITY (state, value) {
    state.community = value
  },
  CLEAR_COMMUNITY (state) {
    state.community = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
