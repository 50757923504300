import http from '@/services/http'

const state = {
}

// getters
const getters = {}

// actions
const actions = {
  async getPost ({ commit }, { workOrderId, postId }) {
    try {
      const { data } = await http.get(`moderate/work-orders/${workOrderId}/community/posts/${postId}`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getComment ({ commit }, { workOrderId, commentId }) {
    try {
      const { data } = await http.get(`moderate/work-orders/${workOrderId}/community/comments/${commentId}`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async getCommentsForPost ({ commit }, { workOrderId, postId }) {
    try {
      const { data } = await http.get(`moderate/work-orders/${workOrderId}/community/posts/${postId}/comments`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async moderate ({ commit }, { moderatableType, moderatableId, status, note }) {
    try {
      const { data } = await http.post(`moderate/${moderatableType}/${moderatableId}`, {
        type: status,
        note
      })
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
