import http from '@/services/http'

const state = {
  ruleGroups: []
}

// getters
const getters = {
  ruleGroups: (state) => state.ruleGroups
}

// actions
const actions = {
  async getRuleGroups ({ commit }, { communityId }) {
    try {
      const { data } = await http.get(`communities/${communityId}/moderation-rule-groups`)
      commit('SET_RULE_GROUPS', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async createRule ({ commit }, { communityId, rule }) {
    try {
      const { data } = await http.post(`communities/${communityId}/moderation-rules`, rule)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updateRule ({ commit }, { communityId, rule }) {
    try {
      const { data } = await http.patch(`communities/${communityId}/moderation-rules/${rule.id}`, rule)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async deleteRule ({ commit }, { communityId, rule }) {
    try {
      const { data } = await http.delete(`communities/${communityId}/moderation-rules/${rule.id}`, rule)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_RULE_GROUPS (state, value) {
    state.ruleGroups = value
  },
  CLEAR_RULE_GROUPS (state) {
    state.ruleGroups = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
