import http from '@/services/http'

const state = {
  optinable: null
}

// getters
const getters = {}

// actions
const actions = {
  async updateOptinable ({ commit }, { optinableId, payload }) {
    try {
      const { data } = await http.patch(`optinables/${optinableId}`, payload)
      commit('SET_OPTINABLE', data.data)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
  SET_OPTINABLE (state, value) {
    state.optinable = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
