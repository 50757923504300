import http from '@/services/http'

const state = {
  conditionProperties: []
}

// getters
const getters = {}

// actions
const actions = {
  async getGroupedConditions ({ commit }, { policyId, subsectionId }) {
    const { data } = await http.get(`policies/${policyId}/subsections/${subsectionId}/conditionals`)
    return data.data
  },
  async getConditionProperties ({ commit }) {
    const { data } = await http.get('app-home-subsection-conditional-properties')
    commit('SET_CONDITION_PROPERTIES', data.data)
  },
  async createCondition ({ commit }, { policyId, subsectionId, params }) {
    const { data } = await http.post(`policies/${policyId}/subsections/${subsectionId}/conditionals`, params)
    return data.data
  },
  async updateCondition ({ commit }, { policyId, subsectionId, conditionId, params }) {
    const { data } = await http.patch(`policies/${policyId}/subsections/${subsectionId}/conditionals/${conditionId}`, params)
    return data.data
  },
  async deleteCondition ({ commit }, { policyId, subsectionId, conditionId }) {
    const { data } = await http.delete(`policies/${policyId}/subsections/${subsectionId}/conditionals/${conditionId}`)
    return data.data
  },
  async saveConditionOrder (c, { policyId, subsectionId, params }) {
    const { data } = await http.post(`policies/${policyId}/subsections/${subsectionId}/conditionals/order`, params)
    return data
  }
}

// mutations
const mutations = {
  SET_CONDITION_PROPERTIES (state, value) {
    state.conditionProperties = value
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
