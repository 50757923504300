import http from '@/services/http'
import { debounce } from 'lodash'
import { FIXED_GROUP, SCROLLABLE_GROUP } from '@/components/policies/edit/app-home/components'

const SET_COMPONENTS = 'SET_COMPONENTS'
const SET_SUBSECTIONS = 'SET_SUBSECTIONS'
const ADD_SUBSECTION = 'ADD_SUBSECTION'
const UPDATE_SUBSECTION = 'UPDATE_SUBSECTION'
const DELETE_SUBSECTION = 'DELETE_SUBSECTION'
const SET_SUBSECTION_COMPONENTS = 'SET_SUBSECTION_COMPONENTS'
const ADD_SUBSECTION_COMPONENT = 'ADD_SUBSECTION_COMPONENT'
const UPDATE_SUBSECTION_COMPONENT = 'UPDATE_SUBSECTION_COMPONENT'
const DELETE_SUBSECTION_COMPONENT = 'DELETE_SUBSECTION_COMPONENT'
const SET_SUBSECTION_COMPONENT_LINKS = 'SET_SUBSECTION_COMPONENT_LINKS'
const SET_SUBSECTION_COMPONENT_GOALS = 'SET_SUBSECTION_COMPONENT_GOALS'
const SET_APP_HOME = 'SET_APP_HOME'
const SET_ACTIVE_SUBSECTION = 'SET_ACTIVE_SUBSECTION'

const state = {
  appHome: {},
  components: {},
  activeSubsection: null,
  subsections: [],
  subsectionComponents: [],
  subsectionComponentLinks: {},
  subsectionComponentGoals: {}
}

// getters
const getters = {
  getAppHome: (state) => state.appHome,
  getActivePolicy: (state, getters, rootState) => {
    return rootState.policies.policy
  },
  isAppHomeLocked: (state, getters, rootState, rootGetters) => {
    const isPolicyLocked = rootGetters['policies/isCurrentPolicyLocked']
    const isSuperAdmin = rootGetters['auth/isSuperAdmin']
    return isPolicyLocked || !isSuperAdmin
  },
  getSubsections: (state) => state.subsections,
  getActiveSubsection: (state) => state.activeSubsection,
  getComponent: (state) => (componentId) => {
    return state.components[componentId]
  },
  getComponents: state => Object.values(state.components),
  /**
   * Get links as an array of IDs
   */
  getSubsectionComponentLinksArray: (state, getters) => (subsectionComponentId) => {
    return getters.getSubsectionComponentLinks(subsectionComponentId).map(link => link.id)
  },
  /**
   * Get links as objects
   */
  getSubsectionComponentLinks: (state) => (subsectionComponentId) => {
    return state.subsectionComponentLinks[subsectionComponentId]
  },
  /**
   * Get goals as an array of IDs
   */
  getSubsectionComponentGoalsArray: (state, getters) => (subsectionComponentId) => {
    return getters.getSubsectionComponentGoals(subsectionComponentId).map(goal => goal.id)
  },
  /**
   * Get goals as objects
   */
  getSubsectionComponentGoals: (state) => (subsectionComponentId) => {
    return state.subsectionComponentGoals[subsectionComponentId]
  },
  getSubsectionComponents: (state) => state.subsectionComponents,
  getSubsectionComponentGroups: state => {
    const subsectionComponentGroups = [
      {
        title: FIXED_GROUP,
        components: []
      },
      {
        title: SCROLLABLE_GROUP,
        components: []
      }
    ]
    state.subsectionComponents.forEach(sc => {
      const group = subsectionComponentGroups.find(g => g.title === sc.group)
      if (group) {
        group.components.push(sc)
      }
    })

    return subsectionComponentGroups
  }
}

// actions
const actions = {
  setAppHome ({ commit }, appHome) {
    commit(SET_APP_HOME, appHome)
  },
  reset ({ commit }) {
    commit(SET_APP_HOME, {})
    commit(SET_ACTIVE_SUBSECTION, null)
    commit(SET_SUBSECTIONS, [])
    commit(SET_SUBSECTION_COMPONENTS, [])
    commit(SET_SUBSECTION_COMPONENT_LINKS, {})
  },
  async index (c, policyId) {
    const { data } = await http.get(`policies/${policyId}/app-homes`)
    return data.data
  },
  async create ({ commit }, policyId) {
    const { data } = await http.post(`policies/${policyId}/app-homes`)
    commit(SET_APP_HOME, data.data)
  },
  async listComponents ({ commit }) {
    const { data } = await http.get('policies/app-home/components')
    // Build a map where the keys are the IDs
    const componentMap = data.data.reduce((obj, item) => {
      return {
        ...obj,
        [item.id]: item
      }
    }, {})
    commit(SET_COMPONENTS, componentMap)
  },
  async listSubsections ({ commit }, policyId) {
    const { data } = await http.get(`policies/${policyId}/app-home/subsections`)
    commit(SET_SUBSECTIONS, data.data)
  },
  async reorderSubsections ({ commit }, payload) {
    const { policyId, subsections } = payload
    const newOrderPayload = subsections.map((subsection) => subsection.id)
    const response = await http.post(`policies/${policyId}/app-home/subsections/reorder`, { ids: newOrderPayload })
    if (response.status === 200) {
      commit(SET_SUBSECTIONS, subsections)
    }
  },
  async addSubsection ({ commit, dispatch, state }, payload) {
    const { policyId, subsection } = payload
    const response = await http.post(`policies/${policyId}/app-home/subsections`, subsection)
    if (response.status === 200) {
      commit(ADD_SUBSECTION, response.data.data)
      if (state.subsections.length === 1) {
        // If this is our first subsection, set it to active so the canvas will display it
        dispatch('setActiveSubsection', state.subsections[0] ?? null)
      }
    }
  },
  updateSubsection: debounce(async function ({ dispatch }, payload) {
    // Wrap in debounce to wait for typing, because we update subsection names in the background, no save action
    await dispatch('updateSubsectionImmediate', payload)
  }, 500),
  async updateSubsectionImmediate ({ commit, state }, payload) {
    const { policyId, subsection } = payload
    const response = await http.patch(`policies/${policyId}/app-home/subsections/${subsection.id}`, subsection)
    if (response.status === 200) {
      const index = state.subsections.findIndex((s) => s.id === subsection.id)
      commit(UPDATE_SUBSECTION, {
        index,
        subsection: response.data.data
      })
    }
  },
  async deleteSubsection ({ commit, state }, payload) {
    const { policyId, subsection } = payload
    const index = state.subsections.findIndex((s) => s.id === subsection.id)
    const response = await http.delete(`policies/${policyId}/app-home/subsections/${subsection.id}`)
    if (response.status === 204) {
      commit(DELETE_SUBSECTION, index)
    }
  },
  async setSubsections ({ commit, dispatch }, payload) {
    const { policyId, subsections } = payload
    const newOrderPayload = subsections.map((subsection) => subsection.id)
    const response = await http.post(`policies/${policyId}/app-home/subsections/reorder`, { ids: newOrderPayload })
    if (response.status === 200) {
      commit(SET_SUBSECTIONS, subsections)
    }
    dispatch('setActiveSubsection', subsections[0] ?? null)
  },
  setActiveSubsection ({ commit, dispatch, state }, subsection) {
    commit(SET_SUBSECTION_COMPONENTS, [])
    commit(SET_ACTIVE_SUBSECTION, subsection)
    dispatch('listSubsectionComponents', { policyId: state.appHome.policy_id, subsectionId: subsection.id })
  },
  async addSubsectionComponent ({ commit }, payload) {
    const { policyId, subsectionId, subsectionComponent } = payload
    const response = await http.post(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components`, subsectionComponent)
    if (response.status === 200) {
      commit(ADD_SUBSECTION_COMPONENT, response.data.data)
    }
  },
  async deleteSubsectionComponent ({ commit }, payload) {
    const { policyId, subsectionId, subsectionComponent } = payload
    const index = state.subsectionComponents.findIndex((sc) => sc.id === subsectionComponent.id)
    const response = await http.delete(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/${subsectionComponent.id}`)
    if (response.status === 204) {
      commit(DELETE_SUBSECTION_COMPONENT, index)
    }
  },
  async listSubsectionComponents ({ commit, state }, payload) {
    const { policyId, subsectionId } = payload
    const { data } = await http.get(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components`)
    commit(SET_SUBSECTION_COMPONENTS, data.data)
  },
  async setSubsectionComponents ({ commit, state }, payload) {
    const { components } = payload
    commit(SET_SUBSECTION_COMPONENTS, components)
  },
  async updateSubsectionComponent ({ commit, state }, payload) {
    const { policyId, subsectionId, subsectionComponent } = payload
    try {
      const response = await http.patch(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/${subsectionComponent.id}`, subsectionComponent)
      if (response.status === 200) {
        const index = state.subsectionComponents.findIndex((s) => s.id === subsectionComponent.id)
        commit(UPDATE_SUBSECTION_COMPONENT, {
          index,
          subsectionComponent: response.data.data
        })
      }
    } catch (e) {
      return Promise.reject(e)
    }
  },
  reorderSubsectionComponents: debounce(async function ({ dispatch }, payload) {
    await dispatch('reorderSubsectionComponentsImmediate', payload)
  }, 500),
  async reorderSubsectionComponentsImmediate ({ commit, dispatch, state }, payload) {
    const { policyId, subsectionId, subsectionComponentGroups } = payload
    const subsectionComponents = []
    subsectionComponentGroups.forEach(group => {
      subsectionComponents.push(...group.components)
    })
    // Update the individual components for components whose groups have changed
    subsectionComponents.forEach(subsectionComponent => {
      const subsectionComponentBeforeUpdate = state.subsectionComponents.find(sc => sc.id === subsectionComponent.id)
      if (subsectionComponentBeforeUpdate.group !== subsectionComponent.group) {
        // There is a difference, need to post an update
        dispatch('updateSubsectionComponent', { policyId, subsectionId, subsectionComponent })
      }
    })
    const newOrderPayload = subsectionComponents.map((sc) => sc.id)
    const response = await http.post(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/reorder`, { ids: newOrderPayload })
    if (response.status === 200) {
      commit(SET_SUBSECTION_COMPONENTS, subsectionComponents)
    }
  },
  async setSubsectionComponentGroups ({ commit, dispatch }, payload) {
    const { policyId, componentGroups } = payload

    if (!Array.isArray(componentGroups)) {
      return
    }

    const subsectionComponents = []
    componentGroups.forEach(group => {
      subsectionComponents.push(...group.components)
    })
    dispatch('setSubsectionComponents', { policyId, components: subsectionComponents })
  },
  async listSubsectionComponentLinks ({ commit }, payload) {
    const { policyId, subsectionId, subsectionComponentId } = payload
    const response = await http.get(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/${subsectionComponentId}/links`)
    if (response.status === 200) {
      commit(SET_SUBSECTION_COMPONENT_LINKS, { subsectionComponentId, links: response.data.data })
    }
  },
  async updateSubsectionComponentLinks ({ commit, dispatch, state }, payload) {
    const { policyId, subsectionId, subsectionComponentId, links } = payload
    const linksPayload = { link_ids: links }
    const response = await http.put(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/${subsectionComponentId}/links`, linksPayload)
    if (response.status === 204) {
      dispatch('listSubsectionComponentLinks', { policyId, subsectionId, subsectionComponentId })
    }
  },
  async reorderSubsectionComponentLinks ({ commit }, payload) {
    const { policyId, subsectionId, subsectionComponentId, links } = payload
    // Use the link_subsection_component_id property because that's the primary key of our pivot table
    const newOrderPayload = links.map((links) => links.link_subsection_component_id)
    const response = await http.post(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/${subsectionComponentId}/links/reorder`, { ids: newOrderPayload })
    if (response.status === 200) {
      commit(SET_SUBSECTION_COMPONENT_LINKS, links)
    }
  },
  async listSubsectionComponentGoals ({ commit }, payload) {
    const { policyId, subsectionId, subsectionComponentId } = payload
    const response = await http.get(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/${subsectionComponentId}/goals`)
    if (response.status === 200) {
      commit(SET_SUBSECTION_COMPONENT_GOALS, { subsectionComponentId, goals: response.data.data })
    }
  },
  async updateSubsectionComponentGoals ({ commit, dispatch, state }, payload) {
    const { policyId, subsectionId, subsectionComponentId, goals } = payload
    const goalsPayload = { goal_ids: goals ?? [] }
    const response = await http.put(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/${subsectionComponentId}/goals`, goalsPayload)
    if (response.status === 204) {
      dispatch('listSubsectionComponentGoals', { policyId, subsectionId, subsectionComponentId })
    }
  },
  async reorderSubsectionComponentGoals ({ commit }, payload) {
    const { policyId, subsectionId, subsectionComponentId, goals } = payload
    // Use the goal_subsection_component_id property because that's the primary key of our pivot table
    const newOrderPayload = goals.map((goal) => goal.goal_subsection_component_id)
    const response = await http.post(`policies/${policyId}/app-home/subsections/${subsectionId}/subsection-components/${subsectionComponentId}/goals/reorder`, { ids: newOrderPayload })
    if (response.status === 200) {
      commit(SET_SUBSECTION_COMPONENT_GOALS, goals)
    }
  }
}

// mutations
const mutations = {
  [SET_APP_HOME] (state, appHome) {
    state.appHome = appHome
  },
  [SET_COMPONENTS] (state, components) {
    state.components = components
  },
  [SET_ACTIVE_SUBSECTION] (state, subsection) {
    state.activeSubsection = subsection
  },
  [SET_SUBSECTIONS] (state, subsections) {
    state.subsections = subsections
  },
  [ADD_SUBSECTION] (state, subsection) {
    state.subsections.push(subsection)
  },
  [UPDATE_SUBSECTION] (state, { index, subsection }) {
    state.subsections.splice(index, 1, subsection)
  },
  [DELETE_SUBSECTION] (state, index) {
    state.subsections.splice(index, 1)
  },
  [SET_SUBSECTION_COMPONENTS] (state, subsectionComponents) {
    state.subsectionComponents = subsectionComponents
  },
  [ADD_SUBSECTION_COMPONENT] (state, subsectionComponent) {
    state.subsectionComponents.push(subsectionComponent)
  },
  [UPDATE_SUBSECTION_COMPONENT] (state, { index, subsectionComponent }) {
    state.subsectionComponents.splice(index, 1, subsectionComponent)
  },
  [DELETE_SUBSECTION_COMPONENT] (state, index) {
    state.subsectionComponents.splice(index, 1)
  },
  [SET_SUBSECTION_COMPONENT_LINKS] (state, { subsectionComponentId, links }) {
    state.subsectionComponentLinks = {
      ...state.subsectionComponentLinks,
      [subsectionComponentId]: links
    }
  },
  [SET_SUBSECTION_COMPONENT_GOALS] (state, { subsectionComponentId, goals }) {
    state.subsectionComponentGoals = {
      ...state.subsectionComponentGoals,
      [subsectionComponentId]: goals
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
