import http from '@/services/http'

const state = {
}

// getters
const getters = {
}

// actions
const actions = {
  async updateProgramDelegatePermission ({ commit }, { programId, payload }) {
    try {
      await http.patch(`programs/${programId}/delegate-permissions`, payload)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async updatePolicyDelegatePermission ({ commit }, { policyId, payload }) {
    try {
      await http.patch(`policies/${policyId}/delegate-permissions`, payload)
    } catch (e) {
      return Promise.reject(e)
    }
  },
  async fetchPolicyDelegatePermissions ({ commit }, { policyId, show, query, page = 1 }) {
    try {
      let url = `policies/${policyId}/delegate-permissions?only=${show}`
      if (query) {
        url += `&query=${query}`
      }
      url += `&page=${page}`
      return await http.get(url)
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
