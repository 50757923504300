import http from '@/services/http'
import { get } from '@/utils/lodash'

const state = {
  allClientCompanies: [],
  allRmcCompanies: [],
  userClientCompanies: [],
  associatedCompanies: [],
  associatedCompaniesPolicies: [],
  associatedPolicies: [],
  associatedThemes: [],
  associatedUsers: [],
  billingTerms: [],
  companies: [],
  currentCompany: {},
  onboardCompany: {}
}

// getters
const getters = {
  hasCompany (state) {
    return Object.keys(state.currentCompany).length > 0
  },
  onboardMapIds: state => get(state.onboardCompany, 'mapIds')
}

// actions
const actions = {
  async attachAssociatedPolicy (c, { companyId, policyId, ...params }) {
    await http.post(`companies/${companyId}/policies/${policyId}/attach`, params)
  },
  async createCompany ({ commit }, payload) {
    const { data } = await http.post('companies', payload)
    return data.data
  },
  async createOnboardCompany ({ commit }, payload) {
    const { data } = await http.post('/companies/onboarding/store', payload)
    return data.data
  },
  async deleteCompany ({ commit }, companyId) {
    await http.delete(`companies/${companyId}`)
    commit('SET_CURRENT_COMPANY', null)
  },
  async detachAssociatedCompany (c, { companyId, id }) {
    const { data } = await http.delete(`/companies/${companyId}/companies/detach/${id}`)
    return data.data
  },
  async detachAssociatedPolicy (c, { companyId, policyId }) {
    const { data } = await http.delete(`/companies/${companyId}/policies/${policyId}/detach`)
    return data.data
  },
  async detachAssociatedUser (c, { companyId, id }) {
    const { data } = await http.delete(`/companies/${companyId}/users/${id}`)
    return data.data
  },
  async getCompany ({ commit }, companyId) {
    const { data } = await http.get(`companies/${companyId}`)
    commit('CLEAR_ASSOCIATED_THEMES')
    commit('SET_CURRENT_COMPANY', data.data)
  },
  async getCompanyById ({ commit }, companyId) {
    const { data } = await http.get(`companies/${companyId}`)

    return data.data
  },
  async listUserClientCompanies ({ commit }, userId) {
    const { data } = await http.get(`/users/${userId}/companies?per_page=all&filter=client&condensed=1`)
    commit('SET_USER_CLIENT_COMPANIES', data.data)
  },
  async listAssociatedCompanies ({ commit }, companyId) {
    const { data } = await http.get(`/companies/${companyId}/companies?per_page=all`)
    commit('SET_ASSOCIATED_COMPANIES', data.data)
  },
  async listAssociatedCompaniesPolicies ({ commit }, companyId) {
    const { data } = await http.get(`/companies/${companyId}/companies/policies?per_page=all`)
    commit('SET_ASSOCIATED_COMPANIES_POLICIES', data.data)
  },
  async listAssociatedUsers ({ commit }, { companyId, roles }) {
    const { data } = await http.get(`/companies/${companyId}/users?per_page=all` + (roles ? `&filter[roles]=${roles}` : ''))
    commit('SET_ASSOCIATED_USERS', data.data)
  },
  async listAllClientCompanies ({ commit }) {
    const { data } = await http.get('/companies?filter=client&per_page=all')
    commit('SET_ALL_CLIENT_COMPANIES', data.data)
    return data.data
  },
  async listAllRmcCompanies ({ commit }) {
    const { data } = await http.get('/companies?filter=rmc&per_page=all')
    commit('SET_ALL_RMC_COMPANIES', data.data)
    return data.data
  },
  async listBillingTerms ({ commit }, companyId) {
    const { data } = await http.get(`/companies/${companyId}/billing-terms?per_page=all`)
    commit('SET_BILLING_TERMS', data.data)
    return data.data
  },
  async listCompanies ({ commit }, query) {
    const { data } = await http.get('/companies?per_page=all&filter=rmc,client' + (query ? `&query=${query}` : ''))
    commit('SET_COMPANIES', data.data)
  },
  async listAssociatedPolicies ({ commit }, companyId) {
    const { data } = await http.get(`/companies/${companyId}/policies`)
    commit('SET_ASSOCIATED_POLICIES', data.data)
    return data.data
  },
  async listAssociatedPoliciesNewWorkOrder ({ commit }, companyId) {
    const { data } = await http.get(`/companies/${companyId}/policies?per_page=all`)
    commit('SET_ASSOCIATED_POLICIES', data.data)
    return data.data
  },
  async restoreCompany (c, companyId) {
    await http.post(`companies/${companyId}/restore`)
  },
  async setDefaultPolicy (c, { companyId, policyId }) {
    const { data } = await http.post(`/companies/${companyId}/policies/${policyId}/default`)
    return data
  },
  async syncAssociatedCompanies (c, { companyId, ids }) {
    const { data } = await http.post(`/companies/${companyId}/companies/sync`, { ids })
    return data.data
  },
  async syncAssociatedUsers (c, { companyId, ids }) {
    const { data } = await http.post(`/companies/${companyId}/users/sync`, { ids })
    return data.data
  },
  async syncCompanyPolicies (c, { companyId, ids }) {
    const { data } = await http.post(`/companies/${companyId}/policies/sync`, { ids })
    return data.data
  },
  async updateCompany ({ commit }, { companyId, payload }) {
    const { data } = await http.patch(`companies/${companyId}`, payload)
    commit('SET_CURRENT_COMPANY', data.data)
  },
  updateOnboardCompany ({ commit }, { ...payload }) {
    commit('SET_ONBOARD_COMPANY', payload)
  },
  async attachAssociatedMap (c, { companyId, ...params }) {
    await http.post(`companies/${companyId}/maps/attach`, params)
  },
  async detachAssociatedMap ({ commit }, { companyId, mapId }) {
    await http.delete(`companies/${companyId}/maps/${mapId}/detach`)
  },
  async getMapData ({ commit }, mapIds) {
    const { data } = await http.get(`/maps/templates/index?map_ids=${mapIds}`)
    return data.data
  },
  async listAssociatedMaps (c, companyId) {
    const { data } = await http.get(`/companies/${companyId}/maps?per_page=all`)
    return data.data
  },
  async listCategoryProviderRestrictions ({ commit }, companyId) {
    try {
      const { data } = await http.get(`/companies/${companyId}/data-content-categories`)
      return data.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async detachAllowedCategory ({ commit }, { companyId, programId, categoryId }) {
    try {
      const { data } = await http.post(`/companies/${companyId}/data-content-categories/detach`, {
        programId,
        dataContentCategoryId: categoryId
      })
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async syncCategoryAndProviders ({ commit }, { companyId, programIds, categoryId, providerIds }) {
    try {
      const { data } = await http.post(`/companies/${companyId}/data-content-categories/sync`, {
        categoryId,
        programIds,
        providerIds
      })
      return data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  onBoardingSetMapIds ({ commit }, ids) {
    commit('SET_ONBOARDING_COMPANY_MAP_IDS', ids)
  },
  async listCompanyThemes ({ commit }, companyId) {
    try {
      const { data } = await http.get(`/companies/${companyId}/application-themes`)
      commit('SET_ASSOCIATED_THEMES', data.data)
      return data.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async createTheme ({ commit }, companyId) {
    try {
      const { data } = await http.post(`/companies/${companyId}/application-themes`)
      return data.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async updateTheme ({ commit }, { companyId, themeId, payload }) {
    try {
      const { data } = await http.patch(`/companies/${companyId}/application-themes/${themeId}`, payload)
      return data.data
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async setActiveTheme ({ commit }, { companyId, themeId }) {
    try {
      await http.post(`/companies/${companyId}/application-themes/activate`, { theme_id: themeId })
    } catch (error) {
      return Promise.reject(error)
    }
  }
}

// mutations
const mutations = {
  SET_ALL_CLIENT_COMPANIES (state, value) {
    state.allClientCompanies = value
  },
  SET_ALL_RMC_COMPANIES (state, value) {
    state.allRmcCompanies = value
  },
  SET_USER_CLIENT_COMPANIES (state, value) {
    state.userClientCompanies = value
  },
  SET_ASSOCIATED_COMPANIES (state, value) {
    state.associatedCompanies = value
  },
  SET_ASSOCIATED_COMPANIES_POLICIES (state, value) {
    state.associatedCompaniesPolicies = value
  },
  SET_ASSOCIATED_POLICIES (state, value) {
    state.associatedPolicies = value
  },
  CLEAR_ASSOCIATED_POLICIES (state, value) {
    state.associatedPolicies = []
  },
  SET_ASSOCIATED_USERS (state, value) {
    state.associatedUsers = value
  },
  SET_BILLING_TERMS (state, value) {
    state.billingTerms = value
  },
  SET_COMPANIES (state, value) {
    state.companies = value
  },
  SET_CURRENT_COMPANY (state, value) {
    state.currentCompany = { ...state.currentCompany, ...value }
  },
  SET_ONBOARD_COMPANY (state, value) {
    state.onboardCompany = { ...state.onboardCompany, ...value }
  },
  CLEAR_BILLING_TERMS (state) {
    state.billingTerms = []
  },
  CLEAR_CURRENT_COMPANY (state) {
    state.currentCompany = {}
  },
  CLEAR_ONBOARD_COMPANY (state) {
    state.onboardCompany = {}
  },
  SET_ONBOARDING_COMPANY_MAP_IDS (state, ids) {
    state.onboardCompany.mapIds = ids
  },
  CLEAR_ASSOCIATED_USERS (state) {
    state.associatedUsers = []
  },
  SET_ASSOCIATED_THEMES (state, value) {
    state.associatedThemes = value
  },
  CLEAR_ASSOCIATED_THEMES (state) {
    state.associatedThemes = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
