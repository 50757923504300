import http from '@/services/http'

const state = {
  // Optionally, store results in state if needed.
}

const getters = {
  // Add any getters if you wish to access the state
}

const actions = {
  // GET: work-orders/{work_order}/inbox-activity
  async fetchInboxActivity (_, { workOrderId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/inbox-activity`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // POST: work-orders/{work_order}/inbox-activity
  async storeInboxActivity (_, { workOrderId, notification }) {
    try {
      const { data } = await http.post(`work-orders/${workOrderId}/inbox-activity`, notification)
      return data.data
    } catch (error) {
      return Promise.reject(error)
    }
  },

  // GET: work-orders/{work_order}/inbox-activity/{activity}
  async fetchInboxActivityDetail (_, { workOrderId, activityId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/inbox-activity/${activityId}`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // PATCH: work-orders/{work_order}/inbox-activity/{activity}
  async updateInboxActivity (_, { workOrderId, activityId, payload }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/inbox-activity/${activityId}`, payload)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // DELETE: work-orders/{work_order}/inbox-activity/{activity}
  async deleteInboxActivity (_, { workOrderId, activityId }) {
    try {
      return await http.delete(`work-orders/${workOrderId}/inbox-activity/${activityId}`)
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // PATCH: work-orders/{work_order}/inbox-activity/{activity}/restore
  async restoreInboxActivity (_, { workOrderId, activityId }) {
    try {
      const { data } = await http.patch(`work-orders/${workOrderId}/inbox-activity/${activityId}/restore`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // GET: work-orders/{work_order}/inbox-filters
  async fetchInboxFilters (_, { workOrderId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/inbox-filters`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  },

  // GET: work-orders/{work_order}/unread-counts
  async fetchUnreadCounts (_, { workOrderId }) {
    try {
      const { data } = await http.get(`work-orders/${workOrderId}/unread-counts`)
      return data.data
    } catch (e) {
      return Promise.reject(e)
    }
  }
}

const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
