import http from '@/services/http'

const state = {
  feedbackQuestions: [],
  feedbackQuestion: {},
  feedbackAnswers: [],
  feedbackAnswer: {}
}

// getters
const getters = {
  hasFeedbackQuestion (state) {
    return Object.keys(state.feedbackQuestion).length > 0
  }
}

// actions
const actions = {
  // Feedback Questions
  async listFeedbackQuestions ({ commit }, query) {
    const { data } = await http.get('/feedback/questions' + (query ? `?query=${query}` : ''))
    commit('SET_FEEDBACK_QUESTIONS', data.data)
    return data
  },
  async getFeedbackQuestion ({ commit }, slug) {
    const { data } = await http.get(`/feedback/questions/${slug}`)
    commit('SET_FEEDBACK_QUESTION', data.data)
    return data.data
  },
  async updateFeedbackQuestion ({ commit }, { slug, payload }) {
    const { data } = await http.patch(`/feedback/questions/${slug}`, payload)
    commit('SET_FEEDBACK_QUESTION', data.data)
    return data.data
  },
  async createFeedbackQuestion ({ commit }, payload) {
    const { data } = await http.post('/feedback/questions', payload)
    commit('SET_FEEDBACK_QUESTION', data.data)
    return data.data
  },
  async deleteFeedbackQuestion ({ commit }, slug) {
    await http.delete(`/feedback/questions/${slug}`)
    commit('CLEAR_FEEDBACK_QUESTION')
  },

  // Feedback Answers
  async listFeedbackAnswers ({ commit }, { questionSlug, query }) {
    const queryStr = query ? `&query=${query}` : ''
    const { data } = await http.get(`/feedback/answers?feedback_question_slug=${questionSlug}${queryStr}`)
    commit('SET_FEEDBACK_ANSWERS', data.data)
    return data
  },
  async getFeedbackAnswer ({ commit }, answerId) {
    const { data } = await http.get(`/feedback/answers/${answerId}`)
    commit('SET_FEEDBACK_ANSWER', data.data)
    return data.data
  },
  async updateFeedbackAnswer ({ commit }, { id, payload }) {
    const { data } = await http.patch(`/feedback/answers/${id}`, payload)
    commit('SET_FEEDBACK_ANSWER', data.data)
    return data.data
  },
  async deleteFeedbackAnswer ({ commit }, id) {
    await http.delete(`/feedback/answers/${id}`)
    commit('CLEAR_FEEDBACK_ANSWER')
  }
}

// mutations
const mutations = {
  SET_FEEDBACK_QUESTIONS (state, value) {
    state.feedbackQuestions = value
  },
  SET_FEEDBACK_QUESTION (state, value) {
    state.feedbackQuestion = { ...state.feedbackQuestion, ...value }
  },
  CLEAR_FEEDBACK_QUESTION (state) {
    state.feedbackQuestion = {}
  },
  SET_FEEDBACK_ANSWERS (state, value) {
    state.feedbackAnswers = value
  },
  SET_FEEDBACK_ANSWER (state, value) {
    state.feedbackAnswer = { ...state.feedbackAnswer, ...value }
  },
  CLEAR_FEEDBACK_ANSWER (state) {
    state.feedbackAnswer = {}
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
